import { reactLocalStorage } from "reactjs-localstorage";
const BASE_URL = "https://api.nowthingstodo.com/api/";
// const BASE_URL='http://127.0.0.1:8025/api/'
const ALLOW_ORIGIN = "https://api.nowthingstodo.com";
// const ALLOW_ORIGIN = 'http://127.0.0.1:8025';
const USER_TYPE = "User";

const ipaddress = "https://api.nowthingstodo.com";

function get(endpoint, params) {
  return requestData(endpoint, params);
}

function post(endpoint, params) {
  return requestData(endpoint, params, "POST");
}

function put(endpoint, params) {
  return requestData(endpoint, params, "PUT");
}

function deletemethod(endpoint, params) {
  return fetch(endpoint, params, "DELETE");
}

async function requestData(url, data = {}, method = "GET") {
  let xhr = new XMLHttpRequest();
  xhr.open(method, BASE_URL + url);
  if (checkingAuth()) xhr.setRequestHeader("authorization", checkingAuth());
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  xhr.setRequestHeader("userType", USER_TYPE);

  return new Promise((resolve, reject) => {
    if (method === "GET") {
      xhr.send();
    } else {
      xhr.send(JSON.stringify(data));
    }
    xhr.onload = () => {
      const contentType = xhr.getResponseHeader("Content-Type");
      let response;

      if (contentType && contentType.includes("application/json")) {
        try {
          response = JSON.parse(xhr.response);
        } catch (error) {
          reject({ message: `Error parsing JSON: ${error.message}` });
          return;
        }
      } else {
        reject({
          message: `Expected JSON response but received ${contentType}`,
        });
        return;
      }

      if (xhr.status === 200 || xhr.status === 404 || xhr.status === 204 || xhr.status === 201) {
        resolve(response);
      } else {
        reject(response);
      }
    };
  });
}


//for marchant create
async function marchantrequest(url, data = {}, method = "GET") {
  let xhr = new XMLHttpRequest();
  xhr.open(method, BASE_URL + url);
  if (checkingAuth()) xhr.setRequestHeader("authorization", checkingAuth());
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  xhr.setRequestHeader("userType", "createMarchant");

  return new Promise((resolve, reject) => {
    if (method === "GET") {
      xhr.send();
    } else {
      xhr.send(JSON.stringify(data));
    }
    xhr.onload = () => {
      let response = JSON.parse(xhr.response);
      if (xhr.status === 200 || xhr.status === 404 || xhr.status === 204) {
        resolve(response);
      } else {
        reject(response);
      }
    };
  });
}

async function fileUpload(url, file, callback = () => {}) {
  var xhr = new XMLHttpRequest();
  xhr.upload.onprogress = function (event) {
    let percent = (event.loaded * 100) / event.total;
    callback(percent);
  };

  xhr.onloadend = function () {
    if (xhr.status === 200) {
      console.log("Success");
    } else {
      console.log("error");
    }
  };

  xhr.open("post", BASE_URL + url);
  if (checkingAuth()) xhr.setRequestHeader("Authorization", checkingAuth());
  xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  xhr.setRequestHeader("userType", USER_TYPE);
  return new Promise((resolve, reject) => {
    xhr.send(file);
    xhr.onload = () => {
      let response = JSON.parse(xhr.response);

      resolve(response);
    };
  });
}

function checkingAuth() {
  let token = reactLocalStorage.get("token");
  if (token) {
    return token;
  }
  return false;
}

//..........for resetpass
async function requestData2(url, data = {}, method = "GET") {
  let xhr = new XMLHttpRequest();
  xhr.open(method, BASE_URL + url);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  xhr.setRequestHeader("userType", USER_TYPE);

  return new Promise((resolve, reject) => {
    if (method === "GET") {
      xhr.send();
    } else {
      xhr.send(JSON.stringify(data));
    }
    xhr.onload = () => {
      let response = JSON.parse(xhr.response);
      if (xhr.status) {
        resolve(response);
      } else {
        reject(response);
      }
    };
  });
}

function post2(endpoint, params) {
  return requestData2(endpoint, params, "POST");
}

export default {
  requestData,
  marchantrequest,
  fileUpload,
  get,
  post,
  post2,
  put,
  deletemethod,
  BASE_URL,
};
export { ipaddress };
