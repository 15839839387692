import React from 'react'
import Skeleton from "react-loading-skeleton";

function AttractionLinkSkeleton() {
  return (
    <div className="custContain">
    <div className="row activity-skeleton-container">
      {Array(8)
        .fill()
        .map((_,i) => {
          return (
            <div className="col-xl-3 col-lg-4 col-md-6 col-8" key={i}>
            
                <div className="activity-detail-skeleton ">
                  <Skeleton count={1} height={45} width={"100%"}style={{ borderRadius: '18px' }} />
                  <Skeleton count={1} height={45} width={"75%"}style={{ borderRadius: '18px' }} />
                  
                </div>
              
            </div>
          );
        })}
    </div>
  </div>
  )
}

export default AttractionLinkSkeleton