class LocalStorage {
  constructor(key) {
    this.key = key;
  }

  // Save data to localStorage
  setItem(value) {
    try {
      const jsonData = JSON.stringify(value);
      localStorage.setItem(this.key, jsonData);
    } catch (e) {
      console.error("Error saving to localStorage", e);
    }
  }

  // Retrieve data from localStorage
  getItem() {
    try {
      const jsonData = localStorage.getItem(this.key);
      return jsonData ? JSON.parse(jsonData) : null;
    } catch (e) {
      console.error("Error reading from localStorage", e);
      return null;
    }
  }

  // Remove item from localStorage
  removeItem() {
    try {
      localStorage.removeItem(this.key);
    } catch (e) {
      console.error("Error removing from localStorage", e);
    }
  }

  // Clear all localStorage
  static clearAll() {
    try {
      localStorage.clear();
    } catch (e) {
      console.error("Error clearing localStorage", e);
    }
  }
}

const userStorage = new LocalStorage("user");

export { LocalStorage, userStorage };
