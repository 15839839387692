import React, { useCallback, useEffect, useState } from "react";
import HomeService from "../Service/HomeService";
import HttpClient from "../utils/HttpClient";

const homeContext = React.createContext();

export const useHome = () => React.useContext(homeContext);

function HomeContext({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTabId, setCurrentTabId] = useState("");
  const [activityType, setActivityType] = useState([]);
  const fecthActivityType = useCallback(() => {
    HomeService.activityType().then((res) => {
      if (res && res.status) {
        setActivityType(res.data);
      }
    });
  }, []);

  const fetchHomeData = useCallback(async () => {
    try {
      // setBannerLoading(true);
      let result = await HttpClient.get("v1/home");
      console.log("result", result?.data)
      if (result.status) {
      
        setCurrentTabId(result.data[0]._id);
      }
    } catch (error) { }
    // setBannerLoading(false);
  }, [setCurrentTabId]);

  useEffect(()=>{
    fetchHomeData()
  },[fetchHomeData])


  return (
    <homeContext.Provider
      value={{
        activityType,
        fecthActivityType,
        setCurrentTabId,
        currentTabId,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </homeContext.Provider>
  );
}

export default HomeContext;
