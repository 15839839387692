import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import MainLoader from "../components/Loaders/MainLoader";
import SearchCitySkeleton from "../skeleton/SearchCitySkeleton";
import ReviewPage from "../view/Review/ReviewPage";

const Notfount = lazy(() => import("../view/ErrorPage/Notfount"))
const Home = lazy(() => import("../view/Home/Home"));
const Activities = lazy(() => import("../view/Activities/Activities"));
const AddToCart = lazy(() => import("../view/AddToCart/Index"));
const CulturalExperienceDetails = lazy(
  () => import("../view/CulturalExperienceDetails/Index")
);
const Wishlist = lazy(() => import("../view/Wishlist/Index"));
const MakePayment = lazy(() => import("../view/MakePayment/Index"));
const BookingHistory = lazy(() => import("../view/BookingHistory/Index"));
const BookingHistoryreviewRating = lazy(
  () => import("../view/BookingHistoryreviewRating/Index")
);
const History = lazy(() => import("../view/History/Index"));
const Contact = lazy(() => import("../view/Contact/Index"));
const About = lazy(() => import("../../src/view/AboutUs/Index"));
const Career = lazy(() => import("../../src/view/Career/Index"));
const Terms = lazy(() => import("../../src/view/TermsCondition/Index"));
const Legal = lazy(() => import("../../src/view/Legal/Index"));
const Press = lazy(() => import("../../src/view/Press/Index"));
const Blog = lazy(() => import("../../src/view/Blog/Index"));
const PrivacyPolicy = lazy(() => import("../view/PrivacyPolicy/PrivacyPolicy"));
const SiteMap = lazy(() => import("../view/SiteMap/Index"));
const Information = lazy(() => import("../view/Information/Index"));
const Cookies = lazy(() => import("../view/Cookies/Index"));
const TravelGuides = lazy(() => import("../../src/view/TravelGuides/index"));
const CheckRoutes = lazy(() => import("./CheckRoutes"));
const SearchPage = lazy(() => import("../view/SearchPage/Search"));
const Settings = lazy(() => import("../view/Settings/Setting"));
const SearchSites = lazy(() => import("../view/SearchPage/SearchSites"));
const Gift = lazy(() => import("../view/GiftcardPage/Gift"));
const SendGift = lazy(() => import("../view/GiftcardPage/SendGift"));
const BlogDetail = lazy(() => import("../view/Blogdetails/BlogDetail"));
const ActivityProvider = lazy(
  () => import("../view/Activities/ActivityProvider")
);
const MerchantSupplierForm = lazy(
  () => import("../components/Merchant/MerchantSupplierForm")
);
const GiftCoupon = lazy(() => import("../view/GiftcardPage/GiftCoupon"));
const ForgotPassword = lazy(() => import("../view/Forgetpassword"));
const Checkout = lazy(() => import("../view/Checkout/Checkout"));
const Mainpagelayout = lazy(() => import("../Layout/MainLayout"));

const AppRouter = () => {
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<MainLoader isLoading={true}/>}>
            <Mainpagelayout />
          </Suspense>
        }
      >
        <Route
          path="/"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Home />
            </Suspense>
          }
        />
           <Route
            path="/checkout"
            element={
              <Suspense fallback={<MainLoader isLoading={true}/>}>
                <Checkout />
              </Suspense>
            }
          />
        <Route
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <CheckRoutes />
            </Suspense>
          }
        >
          <Route
            path="/make-payment"
            element={
              <Suspense fallback={<MainLoader isLoading={true}/>}>
                <MakePayment />
              </Suspense>
            }
          />
       
          <Route
            path="/booking-history"
            element={
              <Suspense fallback={<MainLoader isLoading={true}/>}>
                <BookingHistory />
              </Suspense>
            }
          />

          <Route
            path="/send-gift/:id"
            element={
              <Suspense fallback={<MainLoader isLoading={true}/>}>
                <SendGift />
              </Suspense>
            }
          />
          <Route
            path="/settings"
            element={
              <Suspense fallback={<MainLoader isLoading={true}/>}>
                <Settings />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/wishlist"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Wishlist />
            </Suspense>
          }
        />
        <Route
          path="/add-to-cart"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <AddToCart />
            </Suspense>
          }
        />
        <Route
          path="/sites/:type/:id"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Activities />
            </Suspense>
          }
        ></Route>
        <Route
          path="/activity/:id/:slug"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <CulturalExperienceDetails />
            </Suspense>
          }
        />

        <Route
          path="/browsing-history"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <History />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Contact />
            </Suspense>
          }
        />
        <Route
          path="/about-us"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/career"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Career />
            </Suspense>
          }
        />
        <Route
          path="/terms"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Terms />
            </Suspense>
          }
        />
        <Route
          path="/legal"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Legal />
            </Suspense>
          }
        />
        <Route
          path="/press"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Press />
            </Suspense>
          }
        />
        <Route
          path="/blog"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Blog />
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/sitemap"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <SiteMap />
            </Suspense>
          }
        />
        <Route
          path="/information"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Information />
            </Suspense>
          }
        />
        <Route
          path="/cookies"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Cookies />
            </Suspense>
          }
        />
        <Route
          path="/travelguides"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <TravelGuides />
            </Suspense>
          }
        />
        <Route
          path="/activityProvider/:id"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <ActivityProvider />
            </Suspense>
          }
        />
        <Route
          path="/search-cities/:id/:city"
          element={
            <Suspense fallback={<SearchCitySkeleton/>}>
              <SearchPage />
            </Suspense>
          }
        />
        <Route
          path="/search-sites/:id/:city"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <SearchSites />
            </Suspense>
          }
        />
        <Route
          path="/search-countries/:id/:city"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <SearchSites />
            </Suspense>
          }
        />
        <Route
          path="/gift"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Gift />
            </Suspense>
          }
        />
        <Route
          path="/blogdetail/:id/:catName"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <BlogDetail />
            </Suspense>
          }
        />
        <Route
          path="/merchantsupplierform"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <MerchantSupplierForm />
            </Suspense>
          }
        />
        <Route
          path="/myGiftcoupon"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <GiftCoupon />
            </Suspense>
          }
        />
        <Route
          path="/pressDetails"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <BlogDetail />
            </Suspense>
          }
        />
        <Route
          path="/reset-password"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="/givingReview"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <BookingHistoryreviewRating />
            </Suspense>
          }
        />
  <Route
          path="/show-review-page"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <ReviewPage/>
            </Suspense>
          }
        />

        <Route
          path="*"
          element={
            <Suspense fallback={<MainLoader isLoading={true}/>}>
              <Notfount/>
            </Suspense>
          }
        />
      </Route>

      
    </Routes>
  );
};

export default AppRouter;
